<template>
  <v-card
    :elevation="elevation"
    :outlined="outlined"
    class="rounded-lg"
    :to="path"
  >
    <div style="position: relative">
      <v-img
        :src="party.cover"
        class="rounded-lg rounded-b-0"
        style="cursor: pointer"
        :aspect-ratio="16 / 9"
        :style="{
          filter: !active ? `grayscale(80%)` : undefined,
        }"
      />
      <div
        style="position: absolute; top: 10px; left: 10px; bottom: 10px"
        class="d-flex flex-column align-left"
      >
        <div v-if="!active">
          <v-chip color="secondary" label x-small>
            <v-icon x-small left>mdi-clock-outline </v-icon>
            Encerrado
          </v-chip>
        </div>
        <div v-if="party.private">
          <v-chip color="info" label x-small>
            <v-icon x-small left>mdi-account-lock</v-icon>
            Privado
          </v-chip>
        </div>
        <!-- <v-spacer />
        <v-chip v-if="party.Address" color="primary" label small>
          <v-icon x-small left>mdi-map-marker</v-icon>{{ party.Address.city }} -
          {{ party.Address.state }}
        </v-chip> -->
      </div>
    </div>

    <v-card-title class="font-weight-bold">
      {{ party.name }}
      <v-spacer />
      <p class="text-body-2 mb-1 font-weight-medium" v-if="party.Address">
        <v-icon small left>mdi-map-marker</v-icon>{{ party.Address.city }} -
        {{ party.Address.state }}
      </p>
    </v-card-title>
    <v-card-subtitle class="d-flex gap-x-1 justify-space-between align-center">
      <span>
        {{
          party.date | date(active ? "DD [de] MMMM" : "DD [de] MMMM [de] YYYY")
        }}
      </span>
      <span @click.stop="goToOrg" style="cursor: pointer" class="text-end">
        {{ party.Organization.name }}
      </span>
    </v-card-subtitle>
    <!-- <v-card-actions>
      <v-btn color="primary" block @click="goToParty">Ver detalhes</v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import PartyStatus from "../../utils/partyStatus";
export default {
  methods: {
    goToOrg() {
      this.$router.push({
        name: "shop.organization",
        params: {
          orgSlug: this.party.Organization.slug,
        },
      });
    },
    goToParty() {
      this.$router.push({
        name: "shop.party",
        params: {
          orgSlug: this.party.Organization.slug,
          partyId: this.party.slug || this.party.id,
        },
      });
    },
  },
  computed: {
    path() {
      return `/shop/${this.party.Organization.slug}/${
        this.party.slug || this.party.id
      }`;
    },
    active() {
      const status = new PartyStatus(this.party);
      return status.isActive;
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    elevation: {
      type: [String, Number],
      default: 0,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
