<template>
  <div>
    <v-slide-y-transition leave-absolute v-if="loading || !!error">
      <v-card>
        <ErrorHandler v-if="!!error" :error="error" />
      </v-card>
    </v-slide-y-transition>
    <div v-if="!error" class="">
      <div class="mb-4 d-flex flex-column align-center">
        <div style="max-width: 800px; width: 100%">
          <!-- <v-card v-if="loading" class="rounded-xl">
            <v-responsive :aspect-ratio="16 / 9">
              <v-skeleton-loader
                type="image"
                class="rounded-xl h-100"
                height="100%"
              />
            </v-responsive>
          </v-card> -->

          <party-cover-video
            :party="party"
            @showVideo="(e) => (videoCover = e)"
          />
          <div
            :style="{
              marginTop: videoCover ? '16px' : `-60px`,
              marginBottom: videoCover ? '0px' : `0px`,
            }"
            style="transition: margin-top 0.5s ease-in-out"
            class="px-2 px-md-6 px-lg-8 px-xl-10 pb-4"
          >
            <v-card
              ref="galleryCard"
              class="d-flex align-center justify-space-between rounded-lg pa-4 flex-wrap"
              elevation="0"
            >
              <div class="flex-grow-1">
                <span class="text-overline">Galeria</span>
                <v-skeleton-loader
                  type="heading"
                  width="160"
                  height="28"
                  v-if="loading"
                />
                <h5 class="mb-0" v-else>{{ party.name }}</h5>
              </div>
              <v-btn text small @click="goToParty" :disabled="loading">
                Página do evento
              </v-btn>
            </v-card>
          </div>
        </div>
      </div>
      <v-alert
        v-if="canRating"
        @click="rate"
        type="warning"
        class=""
        icon="mdi-star"
      >
        <div class="d-flex align-center gap-2 white--text">
          <span>
            Você participou desse evento! Avalie-o para ajudar a melhorar os
            próximos.
          </span>
          <v-spacer />
          <v-btn small @click="rate"> Avaliar </v-btn>
        </div>
      </v-alert>
      <v-alert v-else-if="rated" type="success">
        Obrigado por avaliar o evento
      </v-alert>

      <!-- <v-alert class="rounded-xl">
        <div class="d-flex align-center gap-2">
          <base-avatar :size="50" avatarStyle="thumbs"></base-avatar>
          <div>
            As fotos desse evento foram tiradas em parceria com a
            <strong>Selva das Atléticas</strong>
          </div>
          <v-spacer />
          <v-btn icon>
            <v-icon>mdi-web</v-icon>
          </v-btn>
        </div>
      </v-alert> -->

      <!-- <h5 class="mb-0">Galeria</h5> -->
      <v-row
        dense
        v-if="loading"
        class="mt-13 sm:mt-14 md:mt-14 lg:mt-14 xl:mt-14 mx-0"
      >
        <v-col cols="4" lg="3" xl="2" v-for="i in 12" :key="i">
          <v-responsive :aspect-ratio="1">
            <v-skeleton-loader
              type="image"
              class="rounded h-100"
              height="100%"
            />
          </v-responsive>
        </v-col>
      </v-row>
      <template v-else>
        <gallery
          v-if="gallery.length"
          @change="view"
          :gallery="gallery"
          :loading="loading || !!loadingFileDownload"
        >
          <template v-slot:actions="{ image }">
            <div>
              <v-alert
                dense
                color="warning"
                v-if="inInstagram"
                class="mx-2 mb-0 px-2"
              >
                <div class="d-flex align-center gap-2 white--text">
                  <v-icon color="white">mdi-exclamation</v-icon>
                  <small>
                    O Instagram não permite o download de imagens. Para baixar,
                    abra a página em um navegador.
                  </small>
                </div>
              </v-alert>
              <div
                v-else
                class="d-flex align-center justify-center w-100 gap-2"
              >
                <v-btn
                  v-if="navigatorShare"
                  color="primary"
                  small
                  @click="shareImage(image)"
                  :disabled="!!loadingFileDownload || inInstagram"
                  :loading="loadingFileDownload === 'share'"
                >
                  <v-icon small>mdi-share-variant</v-icon> Compartilhar
                </v-btn>
                <v-btn
                  color="primary"
                  small
                  @click.stop="download(image)"
                  :disabled="!!loadingFileDownload || inInstagram"
                  :loading="loadingFileDownload === 'download'"
                >
                  <v-icon small>mdi-download</v-icon> Salvar
                </v-btn>
              </div>
            </div>
          </template>
        </gallery>
        <div
          v-else
          style="height: 50vh"
          class="d-flex flex-column justify-center"
        >
          <div class="justify-center d-flex">
            <v-avatar size="75" color="grey lighten-3">
              <v-icon x-large color="grey darken-2">mdi-image-off</v-icon>
            </v-avatar>
          </div>
          <p class="text-center text-subtitle-1 mb-6 mt-1">
            Nenhuma imagem encontrada
          </p>
        </div>
      </template>
    </div>
    <PartyRating
      v-if="canRating"
      @rated="
        canRating = false;
        rated = true;
      "
    />
  </div>
</template>

<script>
import SHOP from "@/services/shop";
import Gallery from "@/components/global/Gallery.vue";
import GALLERY from "@/services/app/gallery.js";
import ErrorHandler from "./ErrorHandler.vue";
import PartyCoverVideo from "../../components/shop/PartyCoverVideo.vue";
import PartyRating from "@/components/app/modals/PartyRating.vue";

export default {
  metaInfo() {
    return {
      title: this.party?.name
        ? `${this.party.name} - Galeria`
        : "Carregando...",
    };
  },
  components: { Gallery, ErrorHandler, PartyCoverVideo, PartyRating },
  data: () => ({
    currentImage: null,
    loading: false,
    error: null,
    code: null,
    videoCover: false,
    gallery: [],
    party: {},
    notified: [],
    canRating: false,
    rated: false,
    loadingFileDownload: false,
    inInstagram: false,
    navigatorShare: !!navigator.share,
  }),
  methods: {
    rate() {
      this.$emit("rating", {
        party: this.party,
        succeeded: false,
      });
    },
    goToParty() {
      this.$router.push({
        name: "shop.party",
        params: this.$route.params,
      });
    },
    async getData(loading = true) {
      try {
        this.loading = loading;
        let response = await SHOP.partyGallery(
          this.$route.params.orgSlug,
          this.$route.params.partyId,
          this.code
        );

        this.party = response.party;
        this.gallery = response.gallery;
        this.canRating = response.canRating;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    manageCode() {
      var sessionStorageItem = window.sessionStorage.getItem("shop-code") || {};
      if (this.$route.hash) {
        this.code = this.$route.hash.replace("#", "");

        window.sessionStorage.setItem("shop-code", {
          ...sessionStorageItem,
          [this.$route.params.partyId]: this.code,
        });

        this.$router.push({ hash: null });
      } else {
        this.code = sessionStorageItem[this.$route.params.partyId] || null;
      }
    },
    async view(image) {
      if (this.notified.includes(image.id)) return;
      try {
        await GALLERY.view(image.id);
        this.notified.push(image.id);
      } catch (e) {
        console.log(e);
      }
    },
    async downloadImage({ id, url }) {
      const response = await fetch(url);
      const blob = await response.blob();
      try {
        await GALLERY.download(id).catch((e) => {});
      } catch (e) {
        console.log(e);
      }
      return blob;
    },
    fileName(image, blob) {
      const tinyId = image.id.toString().padStart(8, "0");
      return `${this.party.name} - ${tinyId}.${blob.type.split("/")[1]}`;
    },
    async shareImage(image) {
      try {
        this.loadingFileDownload = "share";
        if (navigator.share) {
          const blob = await this.downloadImage(image);
          const file = new File([blob], this.fileName(image, blob), {
            type: blob.type,
          });
          navigator.share({
            title: this.party.name,
            text: `Olha essa foto do evento *${this.party.name}*!`,
            files: [file],
          });
        }
      } catch (error) {
        this.error = error.message || "Erro ao baixar a foto";
      } finally {
        this.loadingFileDownload = false;
      }
    },
    async download(image) {
      try {
        this.loadingFileDownload = "download";

        const blob = await this.downloadImage(image);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.target = "_blank";
        a.download = this.fileName(image, blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        this.error = error.message || "Erro ao baixar a foto";
      } finally {
        this.loadingFileDownload = false;
      }
    },
  },
  mounted() {
    this.manageCode();
    this.getData();
    this.inInstagram = window.navigator.userAgent.includes("Instagram");
  },
};
</script>

<style>
.v-skeleton-loader__image {
  height: 100%;
}
</style>
