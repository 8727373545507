<template>
  <div>
    <h5>Comprar com vendedores</h5>

    <v-card class="d-flex flex-column pa-1" outlined rounded="lg">
      <v-card
        v-for="(seller, i) in slicedSellers"
        :key="seller.id"
        @click="sellerMessage(seller)"
        elevation="0"
        class="px-4"
        >
        <v-divider v-if="i !== 0" class="mb-2"></v-divider>
        <div class="d-flex align-center gap-2 my-2">
          <base-avatar :size="40" :src="seller.photo" :seed="seller.id" />
          <div>
            <p class="mb-0 text-overline lh-1">{{ seller.description }}</p>
            <b>{{ seller.name }}</b>
          </div>
          <v-spacer />
          <v-btn
            x-small
            fab
            color="success"
            elevation="1"
            @click.stop="sellerMessage(seller)"
          >
            <v-icon small>mdi-whatsapp</v-icon>
          </v-btn>
        </div>
      </v-card>

      <v-btn v-if="sellers.length > slice" @click="slice += 3" text small>
        Ver mais
      </v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    slice: 3,
  }),
  methods: {
    sellerMessage(seller) {
      const link = `https://api.whatsapp.com/send?phone=${seller.ddi || 55}${
        seller.phone.replace(/\D/g, "") || 0
      }&text=Olá, ${
        seller.name
      }! Gostaria de comprar ingressos para o evento ${this.party.name}.`;

      window.open(link, "_blank");
    },
  },
  computed: {
    slicedSellers() {
      return this.sellers.slice(0, this.slice);
    },
  },
  props: {
    party: {
      type: Object,
    },
    sellers: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
