<template>
  <canvas
    ref="canvas"
    style="width: 100%"
    :style="{
      height: height,
    }"
  >
  </canvas>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import threeHandleZIP from "@/utils/threeHandleZIP";

const defaultOptions = () => ({
  antialias: true,
  alpha: true,
  camera: {
    fov: 45,
    aspect: 2,
    near: 0.1,
    far: 100,
  },
  scene: {
    background: {
      type: "color",
      color: 0x000000,
    },
  },

  controls: {
    enableDamping: false,
    dampingFactor: 0.05,
    rotateSpeed: 0.25,
    maxPolarAngle: Math.PI / 2,
    minPolarAngle: Math.PI / 4,
    enablePan: true,
  },
});

export default {
  data: () => ({
    canvas: null,
    camera: null,
    scene: null,
    renderer: null,
    controls: null,
    loading: false,
  }),
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: Number | String,
      default: "400px",
    },
  },
  watch: {
    loading(val) {
      this.$emit("update:loading", val);
    },
    options: {
      handler: function (val, oldVal) {
        const options = Object.assign(defaultOptions(), val);
        this.setCamera(options);
        this.setController(options);
        this.setScene(options);
      },
      deep: true,
    },
  },
  methods: {
    initRender(options) {
      this.renderer = new THREE.WebGLRenderer({
        antialias: options.antialias,
        canvas: this.canvas,
        preserveDrawingBuffer: true,
      });
      this.renderer.outputEncoding = THREE.sRGBEncoding;
    },
    initCamera(options) {
      this.camera = new THREE.PerspectiveCamera(
        options.camera.fov,
        options.camera.aspect,
        options.camera.near,
        options.camera.far
      );
    },
    initScene() {
      this.scene = new THREE.Scene();

      // {
      //   const color = 0xffffff;
      //   const intensity = 1;
      //   const light = new THREE.AmbientLight(color, intensity);
      //   this.scene.add(light);
      // }

      // Add floor
      {
        const planeSize = 40;

        const loader = new THREE.TextureLoader();
        const texture = loader.load(
          "https://threejs.org/manual/examples/resources/images/checker.png"
        );
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.magFilter = THREE.NearestFilter;
        const repeats = planeSize / 2;
        texture.repeat.set(repeats, repeats);

        const planeGeo = new THREE.PlaneGeometry(planeSize, planeSize);
        const planeMat = new THREE.MeshPhongMaterial({
          map: texture,
          side: THREE.DoubleSide,
        });
        const mesh = new THREE.Mesh(planeGeo, planeMat);
        mesh.rotation.x = Math.PI * -0.5;
        this.scene.add(mesh);
      }

      {
        const skyColor = 0xb1e1ff; // light blue
        const groundColor = 0xb97a20; // brownish orange
        const intensity = 0.6;
        const light = new THREE.HemisphereLight(
          skyColor,
          groundColor,
          intensity
        );
        this.scene.add(light);
      }
      {
        const color = 0xffffff;
        const intensity = 0.8;
        const light = new THREE.DirectionalLight(color, intensity);
        light.position.set(5, 10, 2);
        this.scene.add(light);
        this.scene.add(light.target);
      }
    },
    setCamera(options) {
      this.camera.fov = options.camera.fov;
      this.camera.aspect = options.camera.aspect;
      this.camera.near = options.camera.near;
      this.camera.far = options.camera.far;
    },
    setController(options) {
      this.controls.minPolarAngle = options.controls.minPolarAngle;
      this.controls.maxPolarAngle = options.controls.maxPolarAngle;
      this.controls.enableDamping = options.controls.enableDamping;
      this.controls.dampingFactor = options.controls.dampingFactor;
      this.controls.rotateSpeed = options.controls.rotateSpeed;
      // this.controls.enablePan = options.controls.enablePan;

      this.controls.update();
    },
    setScene(options) {
      if (options.scene.background.type == "color") {
        this.scene.background = new THREE.Color(options.scene.background.color);
      }
    },
    init() {
      const options = Object.assign(defaultOptions(), this.options);

      // Inicia o renderizador
      this.initRender(options);
      // Inicia a camera
      this.initCamera(options);
      // Inicia o controle
      this.controls = new OrbitControls(this.camera, this.canvas);
      this.setController(options);

      // Inicia a cena
      this.initScene(options);
      // this.setScene(options);

      requestAnimationFrame(this.render);
    },
    load({ type, url }) {
      try {
        this.loading = true;
        // this.removeObject(-1);
        switch (type) {
          case "gltf":
            this.loadGLTF(url);
            break;
          case "zip":
            threeHandleZIP(url, this.addGLTF);
            break;
          default:
            console.error("Unknown type", type);
        }
      } catch (e) {
        alert(e);
      }
    },
    getCameraPosition() {
      return this.camera.position;
    },
    removeObject(index) {
      if (index === -1)
        this.scene.remove(this.scene.children[this.scene.children.length - 1]);
      else this.scene.remove(this.scene.children[index]);
    },
    loadGLTF(url) {
      this.loading = 0;
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(url, this.addGLTF, (xhr) => {
        this.loading = xhr.loaded / xhr.total;
      });
    },
    addGLTF(gltf) {
      const root = gltf.scene;
      this.scene.add(root);

      // compute the box that contains all the stuff
      // from root and below
      const box = new THREE.Box3().setFromObject(root);

      const boxSize = box.getSize(new THREE.Vector3()).length();
      const boxCenter = box.getCenter(new THREE.Vector3());

      // set the camera to frame the box
      this.frameArea(boxSize * 1, boxSize, boxCenter, this.camera);

      // update the Trackball controls to handle the new size
      this.controls.maxDistance = boxSize * 2;
      this.controls.target.copy(boxCenter);
      this.controls.update();
      this.loading = false;
      this.$emit("loaded");
    },

    render() {
      if (this.resizeRendererToDisplaySize(this.renderer)) {
        const canvas = this.renderer.domElement;
        this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
        this.camera.updateProjectionMatrix();
      }

      this.renderer.render(this.scene, this.camera);

      requestAnimationFrame(this.render);
    },
    resizeRendererToDisplaySize(renderer) {
      const canvas = renderer.domElement;
      const width = this.canvas.clientWidth;
      const height = canvas.clientHeight;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
        renderer.setSize(width, height, false);
      }
      return needResize;
    },
    frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera) {
      const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
      const halfFovY = THREE.MathUtils.degToRad(camera.fov * 0.5);
      const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);
      // compute a unit vector that points in the direction the camera is now
      // in the xz plane from the center of the box
      const direction = new THREE.Vector3()
        .subVectors(camera.position, boxCenter)
        .multiply(new THREE.Vector3(1, 0, 1))
        .normalize();

      // move the camera to a position distance units way from the center
      // in whatever direction the camera was from the center already
      camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

      // pick some near and far values for the frustum that
      // will contain the box.
      camera.near = boxSize / 100;
      camera.far = boxSize * 100;

      camera.updateProjectionMatrix();

      // point the camera to look at the center of the box
      camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
    },
  },
  mounted() {
    this.canvas = this.$refs.canvas;
    this.init();
  },
};
</script>

<style>
</style>