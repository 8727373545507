<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450"
      contentClass="rounded-xl"
      :overlay-opacity="0.9"
      persistent
      scrollable
    >
      <!-- Confirm ticket group and ticket block to finish sale -->
      <v-card v-if="ticketGroup && ticketBlock" class="overflow-hidden">
        <v-img :src="party.cover" :aspect-ratio="16 / 9" />
        <v-card-text class="pt-1">
          <div class="">
            <span class="text-overline hl-1">
              {{ party.date | date("DD [de] MMMM - HH:mm") }}
            </span>
            <h5>{{ party.name }}</h5>
            <div class="d-flex align-center flex-wrap gap-2 mb-1">
              <h6 class="mb-0 text-14">{{ ticketGroup.name }}</h6>
              <v-divider />
              <h6 class="mb-0 text-14">{{ ticketBlock.name }}</h6>
            </div>

            <!-- price -->
            <div v-if="ticketBlock.canUseMembership" class="font-weight-medium">
              <p class="text-decoration-line-through text-16 text-end mb-0">
                {{ ticketBlock.price | currency }}
              </p>
              <p class="text-16 text-end mb-0">
                {{
                  (ticketBlock.membershipPrice[0].price - ticketBlock.price)
                    | currency
                }}
              </p>
            </div>
            <h6 class="text-18 text-end mt-0 font-weight-black">
              <v-chip
                v-if="ticketBlock.canUseMembership"
                :color="
                  ticketBlock.membershipPrice[0].Membership.backgroundColor
                "
                small
                text-color="white"
                label
                class="font-weight-medium text-14 mr-4"
                :style="{
                  background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                    ticketBlock.membershipPrice[0].Membership.backgroundImg
                  }), ${
                    ticketBlock.membershipPrice[0].Membership.backgroundColor ||
                    '#3333'
                  }80`,
                  backgroundSize: 'cover',
                  border: `1px solid ${
                    ticketBlock.membershipPrice[0].Membership.backgroundColor ||
                    '#000'
                  }`,
                  boxShadow: `0 0 10px  ${
                    ticketBlock.membershipPrice[0].Membership.backgroundColor ||
                    'rgba(0,0,0,.2)'
                  }`,
                }"
              >
                {{ ticketBlock.membershipPrice[0].Membership.name }}
              </v-chip>
              {{
                ticketBlock.canUseMembership
                  ? ticketBlock.membershipPrice[0].price
                  : ticketBlock.price | currency
              }}
            </h6>
          </div>

          <!-- Messages -->
          <v-alert
            v-if="table"
            text
            dense
            type="primary"
            class="rounded-lg mb-4"
          >
            <div class="d-flex align-center gap-2">
              <v-icon color="primary">mdi-table-chair</v-icon>
              <p class="mb-0 font-weight-bold">
                {{ table.group.name }} • {{ table.name }}
              </p>
              <v-spacer />
              <v-chip color="primary" text-color="white" small>
                {{ table.group.capacity }} pessoa{{
                  table.group.capacity > 1 ? "s" : ""
                }}
              </v-chip>
            </div>
            <p class="mb-0">
              Essa compra dá acesso a {{ table.group.capacity }} ingresso{{
                table.group.capacity > 1 ? "s" : ""
              }}, que podem ser transferidos após o pagamento.
            </p>
          </v-alert>

          <v-alert type="primary" text dense>
            Ao clicar em "<i>Comprar</i>", um ingresso será reservado para você.
            Finalize seu pagamento dentro de 30 minutos para garantir sua
            reserva.
          </v-alert>
          <v-alert
            v-if="!ticketBlock.allowTransfer || ticketBlock.canUseMembership"
            dense
            type="info"
            text
            border="bottom"
            class="mb-0"
          >
            <p v-if="ticketBlock.canUseMembership" class="mb-1">
              Você está comprando um ingresso com
              <b class="font-weight-bold">desconto de sócio</b>. O desconto é
              válido apenas para a compra de um ingresso.
            </p>
            <p v-if="!ticketBlock.allowTransfer" class="mb-1">
              O ingresso é
              <b class="font-weight-bold">pessoal e intransferível</b>. Será
              necessário apresentar um documento com foto para entrar no evento.
            </p>
          </v-alert>
          <v-scroll-y-transition hide-on-leave>
            <v-alert
              v-if="error"
              type="error"
              text
              border="left"
              class="mb-0 mt-2"
            >
              {{ error }}
            </v-alert>
          </v-scroll-y-transition>
        </v-card-text>

        <!-- Actions -->
        <v-card-actions class="align-end justify-space-between">
          <v-btn text @click="close" :disabled="loading"> Cancelar </v-btn>
          <v-btn color="primary" @click="runRecaptcha" large :loading="loading">
            Comprar •
            {{
              ticketBlock.canUseMembership
                ? ticketBlock.membershipPrice[0].price
                : ticketBlock.price | currency(true)
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <re-captcha
      v-if="dialog"
      ref="recaptcha"
      @success="buyTicket"
      @error="recaptchaError"
      @close="recaptchaError"
    />
  </div>
</template>

<script>
import SHOP from "@/services/shop";
import ReCaptcha from "@/components/global/ReCaptcha.vue";

export default {
  components: { ReCaptcha },
  data: () => ({
    loading: false,
    error: false,
    dialog: false,
    ticketBlock: false,
    ticketGroup: false,
    table: false,
    code: null,
  }),
  methods: {
    runRecaptcha() {
      this.loading = true;
      this.error = null;
      this.$refs.recaptcha.execute();
    },
    recaptchaError() {
      this.loading = false;
      this.error = "Erro ao validar o captcha";
    },
    async buyTicket(recaptcha) {
      try {
        const { ticket } = await SHOP.buyTicket(
          this.$route.params.orgSlug,
          this.$route.params.partyId,
          this.ticketBlock.id,
          this.table?.id,
          this.code,
          recaptcha
        );

        this.$router.push({
          hash: "#pay",
          name: "app.ticket.details",
          params: { id: ticket.id },
        });
      } catch (err) {
        this.error = err.message || "Ocorreu um erro ao processar o pedido";
      } finally {
        this.loading = false;
      }
    },
    open({ ticketBlock, ticketGroup, code, table }) {
      this.ticketBlock = ticketBlock;
      this.ticketGroup = ticketGroup;
      this.table = table;
      this.code = code;
      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$root.$on("buy-ticket", this.open);
  },
};
</script>

<style></style>
