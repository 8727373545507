<template>
  <div>
    <v-slide-y-transition leave-absolute v-if="!!error">
      <v-card :loading="loading">
        <error-handler v-if="!!error" :error="error" />
      </v-card>
    </v-slide-y-transition>
    <template v-if="!error">
      <party-mobile
        v-if="$vuetify.breakpoint.xsOnly"
        :data="data"
        :code="code"
        :loading="loading"
        @refresh="getParty()"
      />
      <party-desktop
        v-else
        :data="data"
        :code="code"
        :loading="loading"
        @refresh="getParty()"
      />
    </template>
  </div>
</template>

<script>
import SHOP from "@/services/shop";

import PartyMobile from "./PartyMobile.vue";
import PartyDesktop from "./PartyDesktop.vue";
import ErrorHandler from "./ErrorHandler.vue";

export default {
  metaInfo() {
    return {
      title: this.titlePage,
      script: this.partySchema
        ? [
            {
              type: "application/ld+json",
              json: this.partySchema,
            },
          ]
        : [],
    };
  },
  components: {
    PartyMobile,
    PartyDesktop,
    ErrorHandler,
  },
  data: () => ({
    code: null,
    loading: true,
    error: null,
    data: null,
  }),
  methods: {
    async getParty(loading = true) {
      try {
        this.loading = loading;

        let response = await SHOP.party(
          this.$route.params.orgSlug,
          this.$route.params.partyId,
          this.code
        );
        this.data = response;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    manageCode() {
      var sessionStorageItem = window.sessionStorage.getItem("shop-code") || {};
      if (this.$route.hash) {
        this.code = this.$route.hash.replace("#", "");

        window.sessionStorage.setItem("shop-code", {
          ...sessionStorageItem,
          [this.$route.params.partyId]: this.code,
        });

        this.$router.push({ hash: null });
      } else {
        this.code = sessionStorageItem[this.$route.params.partyId] || null;
      }
    },
  },
  computed: {
    ticketPrice() {
      if (!this.data?.ticketGroups) return null;
      const in_stock = this.data.ticketGroups.some((tg) =>
        tg.ticketBlocks.some((t) => t.status == "available")
      );
      if (!in_stock) return false;
      return this.data.ticketGroups.reduce((acc, tg) => {
        const price = tg.ticketBlocks.reduce((acc, t) => {
          if (t.status == "available" && t.price < acc) return t.price;
          return acc;
        }, Infinity);
        if (price < acc) return price;
        return acc;
      }, Infinity);
    },
    titlePage() {
      return this.data?.party?.name || "Shop - Evento";
    },
    partySchema() {
      if (!this.data?.party) return null;
      return {
        "@context": "http://schema.org",
        "@type": "Event",
        eventStatus: "https://schema.org/EventScheduled",
        eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
        name: this.data.party.name,
        description: this.data.party.description,
        startDate: this.data.party.date,
        endDate: this.data.party.endDate,
        location: {
          "@type": "Place",
          name: this.data.party.Address?.name,
          address: {
            "@type": "PostalAddress",
            streetAddress: this.data?.party?.Address?.address,
            addressLocality: this.data?.party?.Address?.city,
            addressRegion: this.data?.party?.Address?.state,
            postalCode: this.data?.party?.Address?.zipCode,
            addressCountry: this.data?.party?.Address?.country,
          },
        },
        image: this.data?.party?.cover,
        offers: {
          "@type": "Offer",
          url: window.location.href,
          price: this.ticketPrice,
          priceCurrency: "BRL",
          availability:
            "http://schema.org/" + ticketPrice ? "InStock" : "SoldOut",
        },
        organizer: {
          "@type": "Organization",
          name: this.data?.organization?.name,
          url:
            window.location.origin + "/shop/" + this.data?.organization?.slug,
        },
      };
    },
  },
  mounted() {
    this.manageCode();
    this.getParty();
  },
};
</script>

<style></style>
