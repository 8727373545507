<template>
  <div ref="bg">
    <!-- Capa e detalhes -->
    <v-row>
      <v-col sm="8">
        <div class="d-flex flex-column" style="gap: 1rem">
          <party-cover-video :party="data?.party" :loading="loading" />
          <template v-if="status === true && !loading">
            <my-ticket
              v-if="data?.tickets && data?.tickets.length > 0"
              :tickets="data?.tickets"
            />
            <template v-else>
              <ticket-group-selector
                :ticket-groups="data?.ticketGroups"
                :party="data?.party"
                :code="code"
                :periods="data?.periods"
                :tableMap="data?.tableMap"
                @refresh="$emit('refresh', false)"
              />
              <party-sellers-list
                v-if="data?.sellers && data?.sellers.length"
                :party="data?.party"
                :sellers="data?.sellers"
                :loading="loading"
              />
            </template>
          </template>
          <v-alert
            v-else-if="!loading"
            text
            type="warning"
            class="mb-0"
            :icon="status == 'finished' ? 'mdi-calendar-remove' : undefined"
          >
            <b v-if="status == 'finished'">Este evento já terminou</b>
            <b v-else>Esse evento está indisponível</b>
          </v-alert>

          <organization-card
            ref="organization"
            :loading="loading"
            :organization="data?.organization"
          />
          <PartyTableMap
            ref="tableMap"
            v-if="data?.tableMap && status === true && !loading"
            :table-map="data?.tableMap"
            :party="data?.party"
            :loading="loading"
            :ticket-groups="data?.ticketGroups"
          />

          <GLTFMapViewer v-if="has3DMap" ref="3dMap" :map="data?.map" />

          <party-gallery
            ref="gallery"
            v-if="hasGallery"
            :gallery="data?.gallery"
          />
          <attractions-list
            ref="attractions"
            v-if="hasAttractions"
            :attractions="data?.attractions"
            :periods="data?.periods"
            :tickets="data?.tickets"
          />
        </div>
      </v-col>

      <!-- Detalhes -->
      <v-col sm="4" class="d-flex flex-column" style="gap: 1rem">
        <v-card class="rounded-lg" elevation="0">
          <v-card-title class="">
            <div class="flex-grow-1">
              <v-chip
                v-if="!loading"
                label
                small
                @click="goTo('policies')"
                :color="data?.party.ageGroup == 0 ? 'success' : 'info'"
                class="float-right ml-2 mt-1"
              >
                {{
                  data?.party.ageGroup == 0
                    ? "Livre"
                    : `+${data?.party.ageGroup}`
                }}
                {{ data?.party.ageWithParent ? "*" : "" }}
              </v-chip>
              <h3 class="mb-0" style="word-break: normal; line-height: 1.2">
                <v-skeleton-loader
                  v-if="loading"
                  type="heading"
                  height="30"
                  width="120"
                />
                <span v-else>
                  {{ data?.party.name }}
                </span>
              </h3>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="d-flex flex-column gap-1">
              <div class="d-flex align-center">
                <v-icon small left>mdi-calendar-start</v-icon>
                <v-skeleton-loader
                  class="flex-grow-1 mb-0 py-1"
                  v-if="loading"
                  type="heading"
                  height="22"
                />
                <span v-else>{{ data?.party.date | formatDate }} até</span>
              </div>
              <div
                class="d-flex align-center"
                v-if="data?.party?.endDate || loading"
              >
                <v-icon small left>mdi-calendar-end</v-icon>
                <v-skeleton-loader
                  class="flex-grow-1 mb-0 py-1"
                  v-if="loading"
                  type="heading"
                  height="22"
                />
                <span v-else>{{ data?.party.endDate | formatDate }}</span>
              </div>
            </div>
            <v-card
              outlined
              v-if="data?.party?.description"
              class="mt-2 pa-3 pb-1"
            >
              <h6 class="mb-0">Descrição</h6>
              <party-description-card :party="data?.party" />
            </v-card>
          </v-card-text>
        </v-card>

        <party-map :party="data?.party" :loading="loading" />

        <!-- Fast links -->
        <v-chip-group column class="px-4" v-if="!loading">
          <v-chip
            v-for="fastLink in fastLinks"
            :key="fastLink.to"
            small
            outlined
            @click="goTo(fastLink)"
          >
            <v-icon v-if="fastLink.icon" left x-small>{{
              fastLink.icon
            }}</v-icon>
            {{ fastLink.label }}
          </v-chip>
        </v-chip-group>

        <party-policies
          :party="data?.party"
          :loading="loading"
          ref="policies"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import PartyStatus from "@/utils/partyStatus";
import PartyDescriptionCard from "@/components/shop/PartyDescriptionCard.vue";
import PartyGallery from "@/components/shop/PartyGallery.vue";
import MyTicket from "@/components/shop/MyTicket.vue";
import TicketGroupSelector from "@/components/shop/TicketGroupSelector.vue";
import AttractionsList from "@/components/global/party/AttractionsList.vue";
import OrganizationCard from "@/components/shop/OrganizationCard.vue";
import PartyMap from "../../components/shop/PartyMap.vue";
import PartyTableMap from "../../components/shop/PartyTableMap.vue";
import PartyCoverVideo from "../../components/shop/PartyCoverVideo.vue";
import PartyPolicies from "../../components/shop/PartyPolicies.vue";
import PartySellersList from "../../components/shop/PartySellersList.vue";
import GLTFMapViewer from "../../components/shop/GLTFMapViewer.vue";

export default {
  components: {
    PartyDescriptionCard,
    PartyGallery,
    MyTicket,
    TicketGroupSelector,
    AttractionsList,
    OrganizationCard,
    PartySellersList,
    PartyMap,
    PartyTableMap,
    PartyPolicies,
    PartyCoverVideo,
    GLTFMapViewer,
  },
  data() {
    return {
      fLinks: [
        {
          icon: "mdi-share-variant",
          label: "Compartilhar",
          func: this.share,
          if: "canShare",
        },
        {
          label: "Organização",
          to: "organization",
        },
        {
          label: "Mapa",
          to: "tableMap",
          if: "hasTableMap",
        },
        {
          label: "Mapa 3D",
          to: "3dMap",
          if: "has3DMap",
        },
        {
          label: "Galeria",
          to: "gallery",
          if: "hasGallery",
        },
        {
          label: "Cronograma",
          to: "attractions",
          if: "hasAttractions",
        },
      ],
    };
  },
  methods: {
    share() {
      navigator.share({
        title: this.data?.party.name,
        text: this.data?.party.name,
        url: window.location.href,
      });
    },
    goTo({ to, func }) {
      if (func) return func();

      const el = this.$refs[to];
      this.$vuetify.goTo(el || to, {
        offset: 30,
        behavior: "smooth",
      });
    },
    setBg() {
      this.$root.$emit("setImgBg", this.data?.party.cover);
    },
  },
  mounted() {
    this.setBg();
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
  watch: {
    "data.party.cover": {
      handler() {
        this.setBg();
      },
    },
  },
  computed: {
    status() {
      if (!this.data?.party) return false;
      const status = new PartyStatus(this.data?.party);
      return status.isActive || status.status;
    },
    canShare() {
      return navigator.share && this.isPublic;
    },
    isPublic() {
      return !this.data?.party?.private;
    },
    userHasTicket() {
      return this.data?.tickets && this.data?.tickets.length > 0;
    },
    hasGallery() {
      return this.data?.gallery && this.data?.gallery.length > 0;
    },
    hasAttractions() {
      return this.data?.attractions && this.data?.attractions.length > 0;
    },
    hasTableMap() {
      return this.data?.tableMap;
    },
    has3DMap() {
      return this.data?.map && this.data?.map;
    },
    fastLinks() {
      return this.fLinks.filter((link) => {
        if (link.if) return this[link.if];
        return true;
      });
    },
  },
  filters: {
    formatDate(date) {
      const momentDate = moment(date);
      var formatPattern = "DD [de] MMMM";

      if (momentDate.year() != moment().year()) formatPattern += " [de] YYYY";
      else if (momentDate.isBefore(moment(), "day"))
        formatPattern += " [de] YYYY";

      formatPattern += " [às] HH[h]mm";

      return momentDate.format(formatPattern);
    },
  },
  props: {
    data: {
      type: Object,
    },
    code: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
