import http from "@/http-common";

const search = async (query) => {
  return await http
    .get(`/shop`, {
      params: {
        ...query,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const organization = async (orgSlug, query) => {
  return await http
    .get(`/shop/${orgSlug}`, {
      params: {
        ...query,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const party = async (orgSlug, partyId, code) => {
  return await http
    .get(`/shop/${orgSlug}/${partyId}`, {
      params: {
        code,
      },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const partyGallery = async (orgSlug, partyId, code) => {
  return await http
    .get(`/shop/${orgSlug}/${partyId}/gallery`, {
      params: { code },
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const buyTicket = async (orgSlug, partyId, ticketBlockId, tableId, code, recaptcha) => {
  return await http
    .post(
      `/shop/${orgSlug}/${partyId}/buy/ticket`,
      {
        ticketBlock: ticketBlockId,
        tableId,
        recaptcha,
      },
      { params: { code } }
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const highlight = async () => {
  return await http
    .get(
      `/shop/highlight`,
    )
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  search,
  organization,
  party,
  partyGallery,
  buyTicket,
  highlight
};
