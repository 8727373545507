<template>
  <v-card outlined>
    <v-card-text>
      <p class="text--caption text-center mt-1 mb-0">
        Os pagamentos são processados pelo Mercado Pago.
      </p>
      <div class="d-flex justify-center align-center flex-wrap">
        <div v-for="(pm, i) in paymentMethods" :key="i">
          <v-divider vertical v-if="i != 0" style="height: 10px" class="mx-1" />
          <!-- <v-icon left small>
            {{ pm.icon }}
          </v-icon> -->
          <span class="text--caption mb-0">
            {{ pm.name }}
          </span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    paymentMethods: [
      {
        name: "Pix",
        icon: "mdi-qrcode",
        color: "success",
      },
      {
        name: "Cartão de Crédito",
        icon: "mdi-credit-card",
        color: "primary",
      },
      {
        name: "Cartão de Caixa",
        icon: "mdi-bank",
        color: "primary",
      },
      {
        name: "Saldo Mercado Pago",
        icon: "mdi-paypal",
        color: "success",
      },
      {
        name: "PayPal",
        icon: "mdi-paypal",
        color: "success",
      },
    ],
  }),
  methods: {},
};
</script>

<style></style>
