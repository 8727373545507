<template>
  <div>
    <v-card outlined rounded="lg">
      <v-card-text>
        <div class="d-flex flex-column gap-2 align-center">
          <v-avatar :color="statusMap[status].color" size="50" class="ma-2">
            <v-icon x-large class="display-1">mdi-ticket</v-icon>
          </v-avatar>
          <p class="mb-0">Você tem um ingresso para o evento!</p>

          <v-alert
            v-for="(ticket, i) of (status == 'pending' ? [tickets[0]] : tickets)"
            :key="i"
            :border="ticket.TicketEntry ? undefined : 'left'"
            text
            :color="ticketStatusLabel[ticket.Payment.status].color"
            style="cursor: pointer"
            v-ripple
            class="mb-0 pr-1 w-full"
            @click="goToTicket(ticket)"
            dense
            contentClass="pr-0"
          >
            <div class="d-flex align-center gap-1">
              <b v-if="status != 'pending' && tickets.length > 1">{{ i + 1 }}.</b>
              <b class="mb-0 text-14 font-weight-medium">
                {{ ticket.TicketBlock.TicketGroup.name }} •
                {{ ticket.TicketBlock.name }}
              </b>
              <v-chip v-if="ticket.TicketEntry" label x-small class="ml-1">
                Usado
              </v-chip>
              <v-spacer />

              <v-chip
                class="flex-shrink-0"
                label
                x-small
                :color="ticketStatusLabel[ticket.Payment.status].color"
              >
                <v-icon x-small left>
                  {{ ticketStatusLabel[ticket.Payment.status].icon }}
                </v-icon>
                {{ ticketStatusLabel[ticket.Payment.status].text }}
              </v-chip>
              <v-icon color="grey">mdi-chevron-right</v-icon>
            </div>
          </v-alert>

          <!-- <v-alert
            v-for="(ticket, i) in tickets"
            :key="ticket.id"
            :color="statusMap[ticket.Payment.status].color"
            class="mt-2"
            dense
            text
            @click="showTicket(ticket.id, { query: { ticketId: ticket.id } })"
          >
            <div class="d-flex align-center gap-1">
              <b v-if="tickets.length > 1"> {{ i + 1 }}. </b>
              <b class="mb-0 text-14 font-weight-medium">
                {{ ticket.TicketBlock.TicketGroup.name }}
              </b>
              <v-spacer />
              <v-chip> </v-chip>
            </div>
          </v-alert> -->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";

export default {
  data: () => ({
    ticketStatusLabel: PAYMENT.ticketStatus,
    statusMap: {
      pending: {
        color: "warning",
        icon: "mdi-clock-outline",
      },
      approved: {
        color: "success",
      },
      succeeded: {
        color: "success",
      },
    },
  }),
  methods: {
    showTicket(ticketId, props) {
      this.$router.push({
        ...props,
        name: "app.ticket.details",
        params: { id: ticketId },
      });
    },
    goToTicket(ticket) {
      this.$router.push({
        name: "app.ticket.details",
        params: { id: ticket.id },
      });
    },
  },
  computed: {
    status() {
      const hasPending = this.tickets.some((ticket) => {
        console.log(PAYMENT.status_db_groups.pending, ticket.Payment.status);
        return PAYMENT.status_db_groups.pending.includes(ticket.Payment.status);
      });
      console.log(hasPending);
      if (hasPending) return "pending";
      return "approved";
    },
  },
  props: {
    tickets: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
