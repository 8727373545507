<template>
  <div>
    <v-card class="d-flex rounded-lg py-2 pr-3 align-center">
      <place-search
        :search-types="searchTypes"
        hiddenItemDetails
        placeholder="Qualquer local"
        class="flex-shrink-1 pt-0"
        v-model="place"
        rounded
        style="max-width: 250px"
      />
      <v-text-field
        rounded
        hide-details
        placeholder="Nome, descrição, organização"
        v-model="search"
        class="pt-0 flex-grow-1"
        @keypress.enter="performSearch"
      />
      <v-btn
        icon
        color="primary"
        :loading="loading"
        large
        @click="performSearch"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-card>

    <p
      class="text-center mt-2"
      :class="{ 'mt-8': $vuetify.breakpoint.smAndDown }"
    >
      {{ searchText }}
    </p>

    <cols-organizer :cols="1" :sm="2" :md="3" :items="sortedResults">
      <template v-slot="{ item: party }">
        <party-item :party="party"></party-item>
      </template>
    </cols-organizer>

    <v-alert v-if="!isAuthenticated" prominent type="info" class="mt-8" text>
      <div class="d-flex flex-wrap justify-space-between align-center">
        <div>
          <b>Não encontrou o evento que procurava?</b>
          <br />
          Tente entrar na sua conta para ver eventos que você foi convidado.
        </div>
        <v-btn color="primary" @click="login">entrar</v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
import SHOP from "@/services/shop";
import { mapGetters } from "vuex";

import PlaceSearch from "../global/PlaceSearch.vue";
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";
import PartyItem from "@/components/shop/PartyItem.vue";
import PartySort from "@/utils/partySortFunction";

export default {
  metaInfo: {
    title: "Eventos",
  },
  data: () => ({
    searchTypes: ["administrative_area_level_2", "administrative_area_level_1"],
    search: "",
    place: null,

    loading: false,
    error: null,
    results: [],

    code: "",
    resultsFilter: {
      search: "",
      place: null,
    },
  }),
  methods: {
    async performSearch() {
      try {
        const { search, place } = this;
        this.loading = true;
        this.error = null;
        const { party } = await SHOP.search({
          q: search || undefined,
          state: place?.state || undefined,
          city: place?.city || undefined,
          code: this.code || undefined,
        });
        this.results = party;
        this.resultsFilter = { search, place };
      } catch (error) {
        this.error = error.message || error;
      } finally {
        this.loading = false;
      }
    },
    manageCode() {
      var sessionStorageItem = window.sessionStorage.getItem("shop-code") || {};
      this.code = Object.values(sessionStorageItem).join(",");
    },
    login() {
      this.$router.push({ name: "sign-in", query: { redirect: "/shop" } });
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    sortedResults() {
      return this.results.sort(PartySort);
    },
    searchText() {
      var text = "";
      if (this.resultsFilter.search)
        text += ` com o termo "${this.resultsFilter.search}"`;

      if (this.resultsFilter.place)
        text += ` em ${this.resultsFilter.place.name}`;
      const length = this.results.length;
      return `Exibindo ${length} resultado${length == 1 ? "" : "s"}${text}.`;
    },
  },
  watch: {
    place: {
      handler() {
        this.performSearch();
      },
      deep: true,
    },
  },
  components: { PlaceSearch, ColsOrganizer, PartyItem },
  mounted() {
    this.manageCode();
    this.performSearch();
  },
};
</script>

<style></style>
