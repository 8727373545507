<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="700"
      :persistent="loading"
      contentClass="rounded-xl"
      :overlay-opacity="0.9"
      scrollable
      eager
    >
      <!-- Confirm ticket group and ticket block to finish sale -->
      <v-card v-if="ticketGroup && ticketBlock" class="overflow-hidden">
        <table-selector
          containerId="table-selector"
          v-model="selectedTable"
          class="rounded-b-0 elevation-0"
          :table-map="tableMap"
          :party="party"
          :filter-group="ticketGroup?.tableGroupId"
          colorMode="available"
        />

        <!-- Actions -->
        <v-card-actions class="mt-2">
          <v-btn icon @click="close" :disabled="loading">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn color="primary" :loading="loading" :disabled="!selectedTable" @click="confirmSelect">
            Selecionar
            <span v-if="selectedTable">
              {{ selectedTable?.group?.name }} • {{ selectedTable?.name }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableSelector from "@/components/global/partyTable/TableSelector.vue";

export default {
  components: {
    TableSelector,
  },
  data: () => ({
    loading: false,
    error: false,
    dialog: false,
    ticketBlock: false,
    ticketGroup: false,
    selectedTable: null,
  }),
  methods: {
    confirmSelect() {
      this.$emit("select", {
        ticketGroup: this.ticketGroup,
        ticketBlock: this.ticketBlock,
        table: this.selectedTable,
      })
    },
    open({ ticketBlock, ticketGroup }) {
      this.ticketBlock = ticketBlock;
      this.ticketGroup = ticketGroup;
      this.selectedTable = null;
      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.selectedTable = null;
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    tableMap: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$root.$on("select-table", this.open);
  },
};
</script>

<style></style>
