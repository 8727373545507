<template>
  <div id="ticket-group-selector">
    <h5>Comprar ingresso</h5>
    <template v-if="ticketGroups.length">
      <v-expansion-panels focusable class="px-2" v-model="ticketGroupPanel">
        <v-expansion-panel
          v-for="(tg, i) in ticketGroupsSorted"
          :key="tg.id"
          :id="`ticket-group-${tg.id}`"
        >
          <v-expansion-panel-header>
            <div class="d-flex align-center gap-x-4 gap-y-1 flex-wrap">
              <b class="text-16">{{ tg.name }}</b>
              <v-scroll-y-transition>
                <div v-if="i != ticketGroupPanel">
                  <v-chip v-if="tg.TableGroup" color="primary" x-small>
                    <v-icon x-small left>
                      mdi-account{{
                        tg.TableGroup.capacity > 1 ? "-group" : ""
                      }}
                    </v-icon>
                    {{ tg.TableGroup.capacity }} pessoa{{
                      tg.TableGroup.capacity > 1 ? "s" : ""
                    }}
                  </v-chip>
                  <v-chip color="primary" v-if="tg.exclusive" x-small>
                    <v-icon x-small left> mdi-star-four-points </v-icon>
                    Exclusivo
                  </v-chip>
                  <v-chip
                    color="primary"
                    v-else-if="
                      periods.length && tg.periods.every((p) => p.access)
                    "
                    x-small
                  >
                    <v-icon x-small left> mdi-star-four-points </v-icon>
                    Acesso completo
                  </v-chip>
                </div>
              </v-scroll-y-transition>
            </div>
            <v-scroll-y-transition leave-absolute>
              <div v-if="i != ticketGroupPanel" class="flex-grow-0">
                <span
                  v-if="tg.lowestPrice"
                  class="text-end text--disabled pr-4"
                >
                  A partir de
                  {{ tg.lowestPrice | currency(true) }}
                </span>
                <span
                  v-else-if="tg.pending"
                  class="text-end text--disabled pr-4"
                >
                  Em breve
                </span>
                <span v-else class="text-end text--disabled pr-4">
                  Esgotado
                </span>
              </div>
            </v-scroll-y-transition>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- {{ tg.TableGroup }} -->
            <v-alert
              type="info"
              text
              dense
              color="primary"
              class="mt-2"
              v-if="tg.TableGroup"
            >
              <p class="mb-0">
                Os ingressos de setor são vendidos para
                <b class="font-weight-bold"
                  >{{ tg.TableGroup.name }}s com lugar marcado
                </b>
                e
                <b class="font-weight-bold">
                  dão direito a {{ tg.TableGroup.capacity }} ingresso{{
                    tg.TableGroup.capacity > 1 ? "s" : ""
                  }}.</b
                >
              </p>
              <p class="mb-0">
                {{ tg.TableGroup.description }}
              </p>
            </v-alert>
            <div class="mt-2" v-if="tg.description">
              <p class="font-weight-bold mb-0">Descrição:</p>
              {{ tg.description }}
            </div>
            <v-divider class="my-2" v-if="tg.description" />
            <div v-if="periods.length">
              <div class="d-flex gap-x-4 gap-y-1 flex-wrap align-center">
                <p class="font-weight-bold mb-0">Períodos com acesso:</p>
                <v-chip
                  color="primary"
                  v-if="tg.periods.every((p) => p.access)"
                  x-small
                >
                  <v-icon x-small left> mdi-star-four-points </v-icon>
                  Acesso completo
                </v-chip>
              </div>
              <v-chip-group>
                <v-tooltip v-for="period in tg.periods" :key="period.id" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-on="on"
                      small
                      :color="period.access ? 'success' : 'warning'"
                      label
                      @click="scrollToPeriod(period.index)"
                      v-bind="attrs"
                    >
                      <span>
                        <v-icon x-small left>
                          {{ !period.access ? "mdi-lock" : "mdi-check" }}
                        </v-icon>
                        {{ period.name || `Período ${period.index + 1}` }}
                      </span>
                    </v-chip>
                  </template>
                  <span>
                    {{ period.access ? "Acesso liberado" : "Sem acesso" }}
                  </span>
                </v-tooltip>
              </v-chip-group>
            </div>

            <v-list>
              <v-list-item dense>
                <h6 class="mb-0">Lotes</h6>
              </v-list-item>
              <v-list-item
                class="border-1"
                v-for="tb in tg.ticketBlocks"
                :key="tb.id"
              >
                <v-list-item-content>
                  <div
                    class="d-flex justify-space-between gap-x-2 gap-y-1 flex-wrap"
                  >
                    <div>
                      <p class="mb-0 text-16 font-weight-medium">
                        {{ tb.name }}
                      </p>
                      <div class="d-flex align-center">
                        <b v-if="tb.status === 'available'">
                          <div
                            class="d-flex align-center gap-x-2 gap-y-1 flex-wrap"
                          >
                            <span
                              :class="{
                                'text-decoration-line-through':
                                  tb.canUseMembership,
                                'font-weight-medium': tb.canUseMembership,
                                'text-16': tb.canUseMembership,
                                'text-18': !tb.canUseMembership,
                                'text--disabled': tb.canUseMembership,
                              }"
                            >
                              {{ tb.price | currency(true) }}
                            </span>

                            <v-chip v-if="!!tg?.TableGroup" label small>
                              {{ tg.TableGroup.capacity }}
                              <!-- pessoa{{
                                tg.TableGroup.capacity > 1 ? "s" : ""
                              }} -->x
                              <!-- <v-icon small right left>
                                mdi-account{{
                                  tg.TableGroup.capacity > 1 ? "-group" : ""
                                }}
                              </v-icon> -->
                              <v-icon small right left>
                                mdi-ticket-account
                              </v-icon>
                            </v-chip>

                            {{ tb.canUse }}

                            <template v-if="tb.alreadyUsed">
                              <v-chip
                                color="secondary"
                                small
                                label
                                @click="showPriceModal({ tb, tg })"
                              >
                                <v-icon x-small left>
                                  mdi-card-account-details-star
                                </v-icon>
                                Desconto já utilizado
                              </v-chip>
                            </template>

                            <template v-else-if="tb.canUseMembership">
                              <b class="font-weight-bold text-18 ml-2">
                                {{
                                  tb.membershipPrice[0].price | currency(true)
                                }}
                              </b>

                              <v-chip
                                :color="
                                  tb.membershipPrice[0].Membership
                                    .backgroundColor
                                "
                                small
                                text-color="white"
                                label
                                class="font-weight-medium text-14"
                                :style="{
                                  background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                                    tb.membershipPrice[0].Membership
                                      .backgroundImg
                                  }), ${
                                    tb.membershipPrice[0].Membership
                                      .backgroundColor || '#3333'
                                  }80`,
                                  backgroundSize: 'cover',
                                  border: `1px solid ${
                                    tb.membershipPrice[0].Membership
                                      .backgroundColor || '#000'
                                  }`,
                                  boxShadow: `0 0 10px  ${
                                    tb.membershipPrice[0].Membership
                                      .backgroundColor || 'rgba(0,0,0,.2)'
                                  }`,
                                }"
                                @click="showPriceModal({ tb, tg })"
                              >
                                <span
                                  :class="{
                                    'text-decoration-line-through':
                                      tb.alreadyUsed,
                                  }"
                                >
                                  {{ tb.membershipPrice[0].Membership.name }}
                                </span>
                                <v-icon v-if="tb.alreadyUsed" right>
                                  mdi-check
                                </v-icon>
                              </v-chip>
                            </template>

                            <template
                              v-else-if="
                                tb.membershipPrice && tb.membershipPrice.length
                              "
                            >
                              <v-chip
                                color="primary"
                                x-small
                                icon="mdi-card-account-details-star"
                                @click="showPriceModal({ tb, tg })"
                              >
                                {{
                                  tb.membershipPrice.length > 1
                                    ? "A partir de"
                                    : ""
                                }}
                                <b class="font-weight-bold text-12 mx-1">
                                  {{
                                    tb.membershipPrice[0].price | currency(true)
                                  }}
                                </b>

                                para
                                <b class="font-weight-bold text-12 mx-1">
                                  {{
                                    tb.membershipPrice.length > 1
                                      ? `socios`
                                      : tb.membershipPrice[0].Membership.name
                                  }}
                                </b>

                                <v-icon right small> mdi-chevron-down </v-icon>
                              </v-chip>
                              <!-- <span
                                class="font-weight-medium text-16 text--disabled ml-2"
                              >
                                Saiba Mais
                              </span> -->
                            </template>
                          </div>
                        </b>
                        <span v-else-if="tb.status === 'sold-out'">
                          Esgotado
                        </span>
                        <span v-else-if="tb.status === 'pending'">
                          Em breve
                        </span>
                      </div>
                    </div>
                    <v-btn
                      v-if="tb.status == 'available' && tg.TableGroup"
                      color="primary"
                      :loading="loading === tb.id"
                      @click="selectTable(tb, tg)"
                    >
                      Selecionar Lugar
                    </v-btn>
                    <v-btn
                      v-else-if="tb.status == 'available'"
                      color="primary"
                      :loading="loading === tb.id"
                      @click="buyTicket({ ticketGroup: tg, ticketBlock: tb })"
                    >
                      Comprar
                    </v-btn>
                  </div>

                  <!-- <v-list-item-title>{{ tb.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="tb.status === 'available'">
                      <b>{{ tb.price | currency }}</b>
                    </span>
                    <span v-else-if="tb.status === 'sold-out'"> Esgotado </span>
                    <span v-else-if="tb.status === 'pending'"> Em breve </span>
                  </v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <accept-payment-methods class="mx-1 mt-2" />
    </template>
    <template v-else>
      <div class="text-center">
        <v-avatar color="grey lighten-4" size="50">
          <v-icon x-large class="display-1 grey darken-1">mdi-ticket</v-icon>
        </v-avatar>
        <p class="text--disabled mt-2">
          Não há ingressos disponíveis para esse evento
        </p>
      </div>
    </template>
    <select-table
      v-if="tableMap"
      :party="party"
      :table-map="tableMap"
      @select="buyTicket"
    />

    <ticket-block-membership-price :party="party" />
    <require-sign-in @success="successSign" />
    <buy-ticket :party="party" @success="refresh" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AcceptPaymentMethods from "../global/AcceptPaymentMethods.vue";
import BuyTicket from "./modal/BuyTicket.vue";
import RequireSignIn from "./modal/RequireSignIn.vue";
import SelectTable from "./modal/SelectTable.vue";
import TicketBlockMembershipPrice from "./modal/TicketBlockMembershipPrice.vue";

export default {
  components: {
    RequireSignIn,
    BuyTicket,
    AcceptPaymentMethods,
    TicketBlockMembershipPrice,
    SelectTable,
  },
  data: () => ({
    loading: false,
    ticketGroupPanel: 0,
    statusOrder: ["sold-out", "available", "pending"],
  }),
  methods: {
    showPriceModal({ tb, tg }) {
      this.$root.$emit("ticket-block-membership-price", {
        ticketBlock: tb,
        ticketGroup: tg,
      });
    },
    refresh() {
      this.$emit("refresh");
    },
    selectTable(ticketBlock, ticketGroup) {
      this.$root.$emit("select-table", { ticketBlock, ticketGroup });
    },
    successSign() {
      this.refresh();
    },
    promiseSignIn() {
      const promise = new Promise((resolve, reject) => {
        this.promiseSignInData = { resolve, reject };
      });
      return promise;
    },
    async buyTicket({ ticketBlock, ticketGroup, table }) {
      try {
        this.loading = ticketBlock.id;
        if (!this.isAuthenticated) {
          this.$root.$emit("require-sign-in");
          return;
        }
        this.$root.$emit("buy-ticket", {
          ticketBlock,
          ticketGroup,
          code: this.code,
          table,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    scrollToPeriod(index) {
      var el = document.getElementById(`period-${index + 1}`);
      if (index < 1) el = document.getElementById("periods");
      if (el)
        this.$vuetify.goTo(el, {
          offset: 30,
          behavior: "smooth",
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ticketGroupsSorted() {
      return this.ticketGroups.map((tg) => {
        return {
          ...tg,
          periods: this.periods.map((p, index) => ({
            ...p,
            index,
            access: p.TicketGroup.some(({ id }) => id === tg.id),
          })),
          TableGroup: this.tableMap?.Groups.find(
            ({ id }) => id === tg.tableGroupId
          ),

          ticketBlocks: tg.ticketBlocks
            .map((tb) => {
              return {
                ...tb,
                membershipPrice: tb.membershipPrice.sort((a, b) => {
                  if (a.canUse && b.canUse) return a.price - b.price;
                  if (a.canUse) return -1;
                  if (b.canUse) return 1;
                  return a.price - b.price;
                }),
                canUseMembership: (tb.membershipPrice || []).some(
                  ({ canUse }) => canUse
                ),
                alreadyUsed: (tb.membershipPrice || []).some(
                  ({ alreadyUsed }) => alreadyUsed
                ),
              };
            })
            .sort((a, b) => {
              const aStatus = this.statusOrder.indexOf(a.status);
              const bStatus = this.statusOrder.indexOf(b.status);
              if (aStatus === bStatus) {
                return a.name.localeCompare(b.name);
              }
              return aStatus - bStatus;
            }),

          pending: tg.ticketBlocks.some((tb) => tb.status === "pending"),
          lowestPrice:
            tg.ticketBlocks
              .filter((tb) => tb.status === "available")
              .sort((a, b) => a.price - b.price)[0]?.price || null,
        };
      });
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    periods: {
      type: Array,
      default: () => [],
    },
    ticketGroups: {
      type: Array,
      required: true,
    },
    code: {
      type: String,
    },
    tableMap: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.$root.$on("select-ticket-group", (ticketGroupId) => {
      const index = this.ticketGroups.findIndex(
        (tg) => tg.id === ticketGroupId
      );
      if (index > -1) this.ticketGroupPanel = index;
    });
  },
};
</script>

<style></style>
