<template>
  <div class="d-flex flex-column px-4" style="gap: 1rem">
    <div>
      <p class="text-subtitle-2 mb-0">Faixa Etária</p>
      <p class="text-body-2 text--secondary">
        <v-skeleton-loader v-if="loading" type="sentences" />
        <age-group-text v-else :party="party" />
      </p>
    </div>
    <div v-if="party?.requireDocuments">
      <p class="text-subtitle-2 mb-0">Documentos necessários</p>
      <p class="text-body-2 text--secondary">
        {{ party.requireDocuments }}
      </p>
    </div>
    <div v-if="party?.dressCode">
      <p class="text-subtitle-2 mb-0">Normas de Vestimenta</p>
      <p class="text-body-2 text--secondary">
        {{ party?.dressCode }}
      </p>
    </div>
    <div>
      <p class="text-subtitle-2 mb-0">Política de reembolso</p>
      <p class="text-body-2 text--secondary mb-1">
        <v-skeleton-loader v-if="loading" type="paragraph" />
        <refund-policy-text v-else :party="party" />
      </p>
      <a
        @click="refoundDialog = true"
        v-if="!loading"
        class="primary--text text-12"
      >
        Como solicitar reembolso?
      </a>
    </div>
    <v-dialog v-model="refoundDialog" max-width="400px">
      <v-card>
        <v-card-title>Solicitação de Reembolso</v-card-title>
        <v-card-text>
          <p class="text-body-2">
            Para solicitar o reembolso, acesse o ingresso na nossa plataforma e
            vá até a seção "Pagamento". Clique no botão
            <v-btn icon small> <v-icon small>mdi-dots-vertical</v-icon> </v-btn>
            e, em seguida, selecione "Reembolso", preenchendo as informações
            necessárias.
          </p>

          <v-alert color="warning" text dense class="mb-4 text-14">
            Os pedidos de reembolso são avaliados pela organização do evento. Se
            estiver tudo certo, avisaremos você pelo WhatsApp.
          </v-alert>
          <p class="text-body-2 mb-0">
            Dúvidas? Fale conosco via
            <a @click="refundWhats" target="_blank">WhatsApp</a>.
          </p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="refoundDialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DescriptionWrap from "../global/DescriptionWrap.vue";
import AgeGroupText from "../global/party/AgeGroupText.vue";
import RefundPolicyText from "../global/party/RefundPolicyText.vue";

export default {
  components: { DescriptionWrap, AgeGroupText, RefundPolicyText },
  data: () => ({
    refoundDialog: false,
    phoneNumber: process.env.VUE_APP_TICKETME_WHATSAPP,
  }),
  methods: {
    refundWhats() {
      window.open(
        `https://api.whatsapp.com/send?phone=${this.phoneNumber}&text=Ol%C3%A1%2C%20gostaria%20de%20solicitar%20um%20reembolso%20do%20evento%20` +
          this.party.name +
          " (" +
          this.$options.filters.date(this.party.date, "DD/MM/YYYY") +
          ")",
        "_blank"
      );
    },
  },
  props: {
    party: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
